.container-web {
    max-width: 600px;
    margin: auto;
    position: relative;
    min-height: 100vh;
    font-family: "Roboto", sans-serif;
    padding-bottom: 120px;
    // background-color: #fbd8e0;
    background-image: url('../../img/bg.jpeg');
    background-repeat: no-repeat;
    background-size: cover;

    .menu-bottom {
        position: fixed;
        bottom: 0;
        left: 0;
        right: 0;
        width: 100%;
        height: 60px;
        background-color:#ea03b8;
        color: '#fff';
        max-width: 500px;
        margin: auto;

        a {
            text-decoration: none;
        }

        span {
            font-size: 14px;
            color: #fff;
            font-weight: 500;
        }
    }

    .item-history {
        font-size: 14px;
        color: #fff;
        height: 42px;
        padding: 0 10px;
        border-bottom: 1px solid rgb(229, 231, 235);
    }


    .box-kyquay {
        height: 32px;
        font-size: 14px;
        padding: 5px 16px;
        background-color: #ea03b8;
        color: #fff;

        .box-kyid {
            color: #fff;
        }


    }

    .button-box {
        button {
            font-size: 14px;
            width: 160px;
            height: 42px;
            box-shadow: 0 0 10px #dfdfdf;
            border-radius: 25px;
            background-color: #ea03b8;
        }
    }

    .box-result {
        height: 64px;

        .list-ball {
            .ball {
                font-size: 25px;
                background-color: #fff;
                width: 35px;
                height: 35px;
                color: #ea03b8;
                display: flex;
                justify-content: center;
                align-items: center;
                font-weight: bold;
                border: 1px solid #ea03b8;

                span {
                    margin-top: 2px;
                }
            }
        }

    }

    .box-datcuoc {
        padding: 0 10px;

        button {
            box-shadow: none !important;
            width: 100%;
            background-color: transparent;
            transition: 0.3s ease-in-out;
            color: #ea03b8;
            font-size: 13px;
            display: flex;
            align-items: center;
            gap: 10px;
            border: 3px solid #3fe3f1;
            border-radius: 8px;

            &.border-right {
                // border-right: 1px solid #fff !important;
            }

            &.active {
                color: #fff;
                background-color: #ea03b8;
            }
        }


    }

    .info-current-user {
        position: fixed;
        padding: 0 10px;
        bottom: 60px;
        left: 0;
        height: 35px;
        background-color:#ea03b8;
        right: 0;
        font-size: 14px;
        color: #fff;
        max-width: 500px;
        margin: auto;
        border-bottom: 1px solid rgba(255, 255, 255, 0.2);
    }

    .box-quay-img {
        -webkit-animation-duration: .5s;
        animation-duration: .5s;
        -webkit-animation-iteration-count: infinite;
        animation-iteration-count: infinite;
        -webkit-animation-name: spin;
        animation-name: spin;
        -webkit-animation-timing-function: linear;
        animation-timing-function: linear;
        height: 120px;
        width: 120px;

        @keyframes spin {
            from {
                transform: rotate(0deg);
            }
            to {
                transform: rotate(360deg);
            }
        }
        
    }

}

.custom-modal-datcuoc {
    .form-input {
        max-height: 230px;
        input {
            border-color: #ea03b8 !important;
            border-width: 2px;
            border-radius: 0;
            width: 146px;
            color: #000;
            font-size: 14px;
            height: 40px;
            box-shadow: none;
            outline: none;
        }

        .sub {
            border-top-left-radius: 8px;
            border-bottom-left-radius: 8px;
        }

        .add {
            border-top-right-radius: 8px;
            border-bottom-right-radius: 8px;
        }

        .add,
        .sub {
            color: #fff;
            font-weight: bold;
            font-size: 20px;
            width: 40px;
            height: 40px;
            background-color: #ea03b8;
            display: flex;
            justify-content: center;
            align-items: center;
        }
    }

    .box-list-fill {
        .fill {
            height: 42px;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 5px;
            color: #000;
            border: 1px solid #ea03b8;
            font-weight: 400;
            font-size: 16px;
            cursor: pointer;

            span {
                margin-top: 2px;
            }

            &.active {
                border-color: transparent;
                color: #fff;
                background-color: #ea03b8;
            }
        }
    }

    .submit {
        color: #ea03b8;
    }

    .modal-dialog {
        justify-content: center;
    }

    .modal-content {
        max-width: 350px;
    }

}


.Toastify__toast-container--bottom-left {
    bottom: 100px;
}

.box-history {

    .box-header {
        height: 48px;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: #ea03b8;
    }

    .custom-table-history {
        font-size: 10px;

        table,
        tr,
        th,
        td {
            border-color: #e2e5ec;
        }

        .list-ball {
            .ball {
                background-color: #ea03b8;
                color: #fff;
                width: 30px;
                height: 30px;
                display: flex;
                justify-content: center;
                align-items: center;
                border-radius: 50%;
            }
        }
    }

    .button-box {
        button {
            font-size: 14px;
            width: 160px;
            height: 42px;
            box-shadow: 0 0 10px #dfdfdf;
            border-radius: 25px;
            background-color: #ea03b8;
        }
    }
}

.w-35 {
    width: 35% !important;
}

.w-65 {
    width: 65% !important;
}


.box-account {
    .box-header {

        background: #ea03b8;
        border-bottom-left-radius: 30px;
        border-bottom-right-radius: 30px;
        overflow: hidden;
        padding: 0 22px;

        .topinfo {
            background: #fff;
            margin-top: 20px;
            border-radius: 10px;
            padding: 20px;
            font-size: 14px;
        }
    }

    .naprut {
        a {
            margin: 10px 0;
            box-shadow: 0 2px 10px rgba(128, 128, 128, 0.2);
            color: #ea03b8;
            padding: 0 40px;
            text-decoration: none;
            display: block;
            font-size: 14px;
        }
    }

    .list-menu {
        padding: 0 40px;

        a {
            display: flex;
            justify-content: space-between;
            text-decoration: none;
            color: #000;
            align-items: center;
            font-size: 14px;
        }
    }
}

.box-add-bank {
    .box-header {
        height: 48px;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: #ea03b8;
    }

    .box-form {

        .form-group-custom {
            padding: 7px 15px;
            font-size: 14px;

            label {
                color: rgb(55, 65, 81);
            }

            input {
                color: rgb(55, 65, 81);
                border: 2px solid #ea03b8;
                box-shadow: none !important;
                font-size: 14px;
                height: 40px;
                border-radius: 6px;
            }


        }

        button {
            background-color: #ea03b8;
        }

    }
}

.box-withdraw {
    .box-header {
        height: 48px;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: #ea03b8;
    }

    .box-content {
        padding: 10px 15px;

        h5 {
            color: #444;
            font-size: 18px;
        }

        .form-withdraw {
            font-size: 18px;
            color: #444;

            label {
                font-size: 18px;
                color: #444;
                white-space: nowrap;
            }

            input {
                border: 2px solid #cc96ea;
                width: 100%;
                color: rgb(55, 65, 81);
                outline: none !important;
                border-radius: 6px;
            }
        }

        .bank {
            margin-bottom: 20px;
            padding: 10px 15px;
            border-radius: 5px;
            background: #cfcfcf;
            color: #000;
            font-size: 18px;
            color: #444;
            line-height: 36px;
        }

        button {
            font-size: 18px;
            background-color: #ea03b8;
        }
    }
}



////////
.box-history-order {
    .box-header {
        background-color: #ea03b8;
        min-height: 50px;
        padding: 10px 20px;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .nav-tabs {
        padding: 0 20px;
        background-color: #ea03b8;

        li {
            width: 50%;
            background-color: #ea03b8 !important;
            text-align: center;
        }

        a {
            width: 100%;
            border: none;
            background-color: #ea03b8 !important;
            color: #fff !important;

            &.active {
                border-bottom: 3px solid #fff;
            }
        }

    }

    .box-history {
        padding: 20px;
    }

    .item {
        display: inline-block;
        width: 100%;
        padding-bottom: 10px;
        border-bottom: 1px solid #eee;
        margin-bottom: 10px;
        color: #000;
        font-size: 16px;
        line-height: 24px;
    }


}

.login-fe {
    font-family: "Roboto", sans-serif;

    .box-auto {
        max-width: 600px;
        margin-left: auto;
        margin-right: auto;
    }

    .login-card {
        box-shadow: none;

        input {
            background: #fff;
            border: 0 !important;
            border-radius: 30px;
            display: block;
            font-size: 16px;
            outline: none !important;
            padding: 15px 20px;
            width: 100%;
        }
    }

    button {
        width: 100% !important;
        border-radius: 25px;
        background-color: #ea03b8 !important;
        border: unset !important;
    }

    .text-more {
        p {
            font-size: 14px;

            a {
                color: #ea03b8;
                text-decoration: none;
            }
        }
    }
}


.box-chat-customer {

    .input-start-group {
        textarea {
            &::-webkit-scrollbar {
                width: 0;
              }
        }
    }

    .menu-bottom {
        display: none !important;
    }

    .info-current-user {
        display: none !important;
    }

    .message-box .chat-box-container {
        height: calc(100vh - 128px) !important;
    }

    .container-web {
        padding-bottom: 0 !important;
    }
}


.login-fe-custom {
    background-image: url('../../img/bg-login.jpg');
    background-position: 100%;
    background-repeat: no-repeat;
    background-size: auto 100%;
    display: flex;
    flex-flow: row wrap;
    height: 100vh;
    max-width: 500px;
    margin: auto;

    .box-login {
        background-color: #fff;
        padding: 32px;
        border-radius: 8px;
        max-width: 384px;
        margin: auto;

        .col-12 {

            input {
                border: 2px solid #ea03b8 !important;
                border-radius: .375rem !important;
                font-size: 14px;
                padding: .75rem 1rem !important;
            }

            .invalid-feedback {
                font-size: 12px;
            }

        }

        button {
            background-color: #ea03b8 !important;
            border-radius: .375rem !important;
            text-transform: uppercase;
        }
    }
}

.custombutton {
    border: 1px solid #ea03b8!important;
    text-transform: uppercase;
    font-size: 13px !important;
    gap: 2px !important;
    height: 100%;
    padding-top: 7px;
    padding-bottom: 7px;
    font-weight: 500;
    font-family: "Roboto", sans-serif;
    text-align: start;
    position: relative;
    padding-right: 30px;
    line-height: 15px;

    svg {
        flex-shrink: 0;
        position: absolute;
        right: 5px;
    }
}

.custom-select-bank {
    .modal-body {
        height: calc(100vh - 100px) !important;
        overflow-x: hidden;
        overflow-y: auto;
    }
}