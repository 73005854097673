.wrapper {
  width: 100%;
  height: 100%;
  @include ltr-rtl('padding-left', var(--cui-sidebar-occupy-start, 0));
  will-change: auto;
  @include transition(padding 0.15s);
}

.container-web {
  max-width: 500px;
  margin: auto;
}

.body {
  background-color: var(--base-white);
  font-weight: 500;
}

body,
html {
  height: 100%;
}

.background-gradient {
  /* ff 3.6+ */
  background: -moz-linear-gradient(0deg, #ff5c00 0%, rgba(253, 168, 75, 1) 100%);

  /* safari 5.1+,chrome 10+ */
  background: -webkit-linear-gradient(0deg, #ff5c00 0%, rgba(253, 168, 75, 1) 100%);

  /* opera 11.10+ */
  background: -o-linear-gradient(0deg, #ff5c00 0%, rgba(253, 168, 75, 1) 100%);

  /* ie 6-9 */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#FF5C00', endColorstr='#FDA84B', GradientType=1 );

  /* ie 10+ */
  background: -ms-linear-gradient(0deg, #ff5c00 0%, rgba(253, 168, 75, 1) 100%);

  /* global 94%+ browsers support */
  background: linear-gradient(0deg, #ff5c00 0%, rgba(253, 168, 75, 1) 100%);
}

.cursor-pointer {
  cursor: pointer;
}

.bg-primary-500 {
  background-color: var(--primary-500);
}

.icon-fill {
  fill: currentcolor;
  color: inherit;
  text-align: center;
  display: inline-block;
}

.icon-stroke {
  stroke: currentcolor;
  color: inherit;
  text-align: center;
  display: inline-block;
}

::-webkit-scrollbar {
  height: 5px;
  width: 5px;
}
::-webkit-scrollbar-thumb {
  background: #4b5565;
  border-radius: 5px;
  &:focus,
  &:active,
  &:hover {
    background: #4b5565;
  }
}
::-webkit-scrollbar-track {
  background: #f8fafc;
  border-radius: 5px;
}


// SCSS code
@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.spin {
  animation: spin 3s linear infinite;
}

#wheel  {
  padding: 16px;
  canvas {
    width: 100%;
    height: 100%;
  }
}

#wheelCircle {
  .disabled-wheel {
    position: absolute;
    border-radius: 50%;
    &.active {
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      z-index: 1000;
    }
  }
}

.soluot {
  -webkit-animation-duration: 2s;
  animation-duration: 2s;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
  -webkit-animation-name: rainbow-border;
  animation-name: rainbow-border;
  border-radius: 5px;
  font-weight: 600;
  text-decoration: none;
  width: fit-content;
  margin: auto;
}

@keyframes rainbow-border {
  0% {
    border-color: red;
    color: black;
  }
  16.666% {
      border-color: orange;
      color: red;
  }
  33.333% {
      border-color: yellow;
      color: black;
  }

  50% {
      border-color: green;
      color: violet;
  }
  66.666% {
      border-color: blue;
      color: black;
  }
  83.333% {
      border-color: indigo;
      color: red;
  }
  100% {
      border-color: violet;
      color: black;
  }
}